import React, { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text } from "../../utils/Text";

function Burger({ isActive, setIsActive }) {
  if (isActive) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  const handleLinkClick = (event) => {
    setIsActive((prevState) => !prevState);
    event.preventDefault();
    const targetId = event.target.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleBurgerLinkClick = () => {
    setIsActive((prevState) => !prevState);
  };

  const { t } = useTranslation();

  return (
    <div
      className="sc-gwVKww jNtSgK"
      style={{
        opacity: isActive ? "1" : "0",
        transform: isActive ? "translateX(0px)" : "translateX(100%)",
        visibility: isActive ? "visible" : "hidden",
      }}
    >
      {window.location.pathname === "/" ? (
        <nav className="sc-hXRMBi dkDPbM">
          <Link
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            to="/"
            target="_self"
            onClick={handleLinkClick}
          >
            <Text tid="SELL.AGENCY.HOME" />
          </Link>

          <a
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            href="#slider-block"
            target="_self"
            onClick={handleLinkClick}
          >
            <Text tid="SELL.AGENCY.OUR_CASES" />
          </a>
          <a
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            href="#difficulties"
            target="_self"
            onClick={handleLinkClick}
          >
            <Text tid="SELL.AGENCY.CONTACTS" />
          </a>
          <a
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            href="#more-info"
            target="_self"
            onClick={handleLinkClick}
          >
            <Text tid="SELL.AGENCY.FAQ" />
          </a>
        </nav>
      ) : (
        <nav className="sc-hXRMBi dkDPbM">
          <Link
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            to="/"
            target="_self"
            onClick={handleBurgerLinkClick}
          >
            <Text tid="SELL.AGENCY.HOME" />
          </Link>
          <Link
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl gMEkqO hoverable MuiTypography-colorTextPrimary"
            to="/slide4"
            target="_self"
            onClick={handleBurgerLinkClick}
          >
            <Text tid="SELL.AGENCY.EXCHANGE" />
          </Link>
          <Link
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            to="/slide2"
            target="_self"
            onClick={handleBurgerLinkClick}
          >
            <Text tid="SELL.AGENCY.DC" />
          </Link>
          <Link
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            to="/slide3"
            target="_self"
            onClick={handleBurgerLinkClick}
          >
            <Text tid="SELL.AGENCY.CSGO" />
          </Link>
          <Link
            className="MuiTypography-root MuiLink-root MuiLink-underlineNone sc-htpNat gilzNU sc-iQNlJl nYNSO hoverable MuiTypography-colorTextPrimary"
            to="/slide1"
            target="_self"
            onClick={handleBurgerLinkClick}
          >
            <Text tid="SELL.AGENCY.KH" />
          </Link>
        </nav>
      )}
    </div>
  );
}

export default Burger;
