import React from "react";
import "../DifficultiesBlock/index.css";
import "../DifficultiesBlock/adaptive.css";
import { Text } from "../../utils/Text";
import picture1 from "./img/Floppy disk-amico.svg";
import picture2 from "./img/Computer troubleshooting-amico.svg";
import picture3 from "./img/Software integration-bro.svg";
import picture4 from "./img/Gaming-cuate.svg";

function DifficultiesBlock() {
  return (
    <div className="content-wrapper-df" id="difficulties">
      <div className="title-df">
        <Text tid="SELL.AGENCY.POTENTIAL_CLIENT_CHALLENGES" />
      </div>
      <div className="example-section example-adaptive-section">
        <div className="block-reverse">
          <div className="rectangle">
            <img src={picture1} alt="" className="rectangle-img" />
          </div>
          <div className="text-block">
            <div className="text-block-title">
              <Text tid="SELL.AGENCY.CHALLENGE_1" />
            </div>
            <div className="text-block-subtitle">
              <Text tid="SELL.AGENCY.CHALLENGE_2" />
            </div>
          </div>
        </div>
        <div className="block-def">
          <div className="text-block">
            <div className="text-block-title">
              <Text tid="SELL.AGENCY.CHALLENGE_3" />
            </div>
            <div className="text-block-subtitle">
              <Text tid="SELL.AGENCY.CHALLENGE_4" />
            </div>
          </div>

          <div className="rectangle">
            <img src={picture2} alt="" className="rectangle-img" />
          </div>
        </div>
        <div className="block-reverse">
          <div className="rectangle">
            <img src={picture3} alt="" className="rectangle-img" />
          </div>
          <div className="text-block">
            <div className="text-block-title">
              <Text tid="SELL.AGENCY.CHALLENGE_5" />
            </div>
            <div className="text-block-subtitle">
              <Text tid="SELL.AGENCY.CHALLENGE_6" />
            </div>
          </div>
        </div>
        <div className="block-def">
          <div className="text-block">
            <div className="text-block-title">
              <Text tid="SELL.AGENCY.CHALLENGE_7" />
            </div>
            <div className="text-block-subtitle">
              <Text tid="SELL.AGENCY.CHALLENGE_8" />
            </div>
          </div>
          <div className="rectangle">
            <img src={picture4} alt="" className="rectangle-img" />
          </div>
        </div>
      </div>
      {/* <div className="example-adaptive-section">
        <div className="rectangle">
          <img src={picture2} alt="" className="rectangle-img" />
        </div>
        <div className="text-block">
          <div className="text-block-title">
            <Text tid="SELL.AGENCY.CHALLENGE_1" />
          </div>
          <div className="text-block-subtitle">
            <Text tid="SELL.AGENCY.CHALLENGE_2" />
          </div>
        </div>
        <div className="rectangle">
          <img src={picture3} alt="" className="rectangle-img" />
        </div>
        <div className="text-block">
          <div className="text-block-title">
            <Text tid="SELL.AGENCY.CHALLENGE_3" />
          </div>
          <div className="text-block-subtitle">
            <Text tid="SELL.AGENCY.CHALLENGE_4" />
          </div>
        </div>
        <div className="rectangle">
          <img src={picture1} alt="" className="rectangle-img" />
        </div>
        <div className="text-block">
          <div className="text-block-title">
            <Text tid="SELL.AGENCY.CHALLENGE_5" />
          </div>
          <div className="text-block-subtitle">
            <Text tid="SELL.AGENCY.CHALLENGE_2" />
          </div>
        </div>
        <div className="rectangle">
          <img src={picture1} alt="" className="rectangle-img" />
        </div>
        <div className="text-block">
          <div className="text-block-title">
            <Text tid="SELL.AGENCY.CHALLENGE_6" />
          </div>
          <div className="text-block-subtitle">
            <Text tid="SELL.AGENCY.CHALLENGE_7" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default DifficultiesBlock;
