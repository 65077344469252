import React from "react";
import { Helmet } from "react-helmet";

import { Text } from '../../utils/Text';
import { useTranslation } from "react-i18next";

import favicon from "./img/favicon.png";

const Head = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t("SELL.AGENCY.HEAD_TITLE")}</title>
        <meta name="description" content={t("SELL.AGENCY.OUR_GOAL")} />
        <link rel="icon" type="image/x-icon" href={favicon} />
      </Helmet>
    </div>
  );
};

export default Head;