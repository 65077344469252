import React from "react";
import "./index.css";
import "./adaptive.css";
import { Text } from "../../utils/Text";

function Services() {
  const handleTelClick = () => {
    window.open("https://t.me/thedarkagency", "_blank");
  };
  return (
    <div className="content-wrapper-sv">
      <div className="title-cv">
        <Text tid="SELL.AGENCY.PROJECT_CONDITIONS" />
      </div>
      <div className="iAJkRe">
        <div className="eTUaop">
          <h2 className="dmDhoc">
            <Text tid="SELL.AGENCY.WEB_APPLICATIONS" />
          </h2>
          <div className="dLJVyQ">
            <Text tid="SELL.AGENCY.WEB_APPS_DESCRIPTION" />
          </div>
          <div className="ehgbit">
            <div className="card-panel">
              <div className="hiCwcJ">
                <Text tid="SELL.AGENCY.WEB_APP_PRICE" />
              </div>
              <button className="price-button" onClick={handleTelClick}>
                <Text tid="SELL.AGENCY.WEB_APP_ORDER" />
              </button>
            </div>
          </div>
        </div>
        {/* <div className="eTUaop">
          <h2 className="dmDhoc">
            <Text tid="SELL.AGENCY.SYSTEMS_FOR_BUSINESS" />
          </h2>
          <div className="dLJVyQ">
            <Text tid="SELL.AGENCY.SYSTEMS_DESCRIPTION" />
          </div>
          <div className="ehgbit">
            <div className="card-panel">
              <div className="hiCwcJ">
                <Text tid="SELL.AGENCY.SYSTEMS_PRICE" />
              </div>
              <button className="price-button" onClick={handleTelClick}>
                <Text tid="SELL.AGENCY.SYSTEMS_ORDER" />
              </button>
            </div>
          </div>
        </div> */}
        {/* <div className="eTUaop">
          <h2 className="dmDhoc">
            <Text tid="SELL.AGENCY.DIGITALIZATION_OF_BUSINESS" />
          </h2>
          <div className="dLJVyQ">
            <Text tid="SELL.AGENCY.DIGITALIZATION_DESCRIPTION" />
          </div>
          <div className="ehgbit">
            <div className="card-panel">
              <div className="hiCwcJ">
                <Text tid="SELL.AGENCY.DIGITALIZATION_PRICE" />
              </div>
              <button className="price-button" onClick={handleTelClick}>
                <Text tid="SELL.AGENCY.WEB_APP_ORDER" />
              </button>
            </div>
          </div>
        </div> */}
        <div className="eTUaop">
          <h2 className="dmDhoc">
            <Text tid="SELL.AGENCY.CRYPTO_PROJECTS_AND_TRADING" />
          </h2>
          <div className="dLJVyQ">
            <Text tid="SELL.AGENCY.CRYPTO_PROJECTS_DESCRIPTION" />
          </div>
          <div className="ehgbit">
            <div className="card-panel">
              <div className="hiCwcJ">
                <Text tid="SELL.AGENCY.CRYPTO_PROJECTS_PRICE" />
              </div>
              <button className="price-button" onClick={handleTelClick}>
                <Text tid="SELL.AGENCY.WEB_APP_ORDER" />
              </button>
            </div>
          </div>
        </div>
        <div className="eTUaop">
          <h2 className="dmDhoc">
            <Text tid="SELL.AGENCY.BOTS_AND_PARSERS" />
          </h2>
          <div className="dLJVyQ">
            <Text tid="SELL.AGENCY.BOTS_DESCRIPTION" />
          </div>
          <div className="ehgbit">
            <div className="card-panel">
              <div className="hiCwcJ">
                <Text tid="SELL.AGENCY.BOTS_PRICE" />
              </div>
              <button className="price-button" onClick={handleTelClick}>
                <Text tid="SELL.AGENCY.WEB_APP_ORDER" />
              </button>
            </div>
          </div>
        </div>
        <div className="eTUaop">
          <h2 className="dmDhoc">
            <Text tid="SELL.AGENCY.WEBSITES_FOR_BUSINESS" />
          </h2>
          <div className="dLJVyQ">
            <Text tid="SELL.AGENCY.WEBSITES_DESCRIPTION" />
          </div>
          <div className="ehgbit">
            <div className="card-panel">
              <div className="hiCwcJ">
                <Text tid="SELL.AGENCY.WEBSITES_PRICE" />
              </div>
              <button className="price-button" onClick={handleTelClick}>
                <Text tid="SELL.AGENCY.WEB_APP_ORDER" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
