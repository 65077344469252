import React from "react";
import "../InfoBlock/index.css";
import "../InfoBlock/adaptive.css";
import yearImage from "./img/Year.png";
import thousandImage from "./img/Thousand.png";
import percentImage from "./img/Perсent.png";
import thirtyImage from "./img/Thirty.png";
import millionsImage from "./img/Millions.png";
import tenImage from "./img/Ten.png";
import styled from "styled-components";
import { Text } from "../../utils/Text";

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 450px;
  @media (max-width: 570px) {
    align-items: center;
  }
`;

const TextBlockTitile = styled.div`
  font-size: 21px;
  line-height: 28px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 5px;
`;
const TextBlockSubtitle = styled.div`
  font-size: 21px;
  line-height: 28px;
  color: #ffffff;
  @media (max-width: 570px) {
    text-align: center;
  }
`;

function Info() {
  return (
    <div className="content-wraper-info" id="info">
      <div className="main-content">
        <div className="title">
          <Text tid="SELL.AGENCY.OUR_ACHIEVEMENTS" />
        </div>
        <div className="segment-wrapper">
          <div className="left-inf-block">
            <div className="info-segment">
              {/* <div className="img-block"> */}
              {/* <img src={yearImage} style={{ marginRight: "30px" }}></img> */}
              <div class="sc-gipzik joNsQm">
                <p class="sc-csuQGl kMPoVz">
                  <span class="sc-gPEVay bNalEw">
                    2+
                    <br />
                    <Text tid="SELL.AGENCY.YEARS_ON_MARKET" />
                  </span>
                </p>
                {/* </div> */}
              </div>
              <TextBlock>
                <TextBlockTitile>
                  <Text tid="SELL.AGENCY.YEARS_ON_MARKET1" />
                </TextBlockTitile>
                <TextBlockSubtitle>
                  <Text tid="SELL.AGENCY.MONEY_SAVED" />
                </TextBlockSubtitle>
              </TextBlock>
            </div>
            <div className="info-segment">
              {/* <div className="img-block">
                <img src={thousandImage} style={{ marginRight: "30px" }}></img>
              </div> */}
              <div class="sc-gipzik joNsQm">
                <p class="sc-csuQGl kMPoVz">
                  <span class="sc-gPEVay bNalEw">
                    $500
                    <br />
                    <Text tid="SELL.AGENCY.MONEY_SAVED1" />
                  </span>
                </p>
              </div>
              <TextBlock>
                <TextBlockTitile>
                  <Text tid="SELL.AGENCY.CLIENTS_EARNED" />
                </TextBlockTitile>
                <TextBlockSubtitle>
                  <Text tid="SELL.AGENCY.CLIENTS_EARNED_AMOUNT" />
                </TextBlockSubtitle>
              </TextBlock>
            </div>
            <div className="info-segment">
              {/* <div className="img-block">
                <img src={percentImage} style={{ marginRight: "30px" }}></img>
              </div> */}
              <div class="sc-gipzik joNsQm">
                <p class="sc-csuQGl kMPoVz">
                  <span class="sc-gPEVay bNalEw">100%</span>
                </p>
              </div>
              <TextBlock>
                <TextBlockTitile>
                  <Text tid="SELL.AGENCY.SATISFIED_CLIENTS" />
                </TextBlockTitile>
                <TextBlockSubtitle>
                  <Text tid="SELL.AGENCY.SATISFIED_CLIENTS_PERCENT" />
                </TextBlockSubtitle>
              </TextBlock>
            </div>

            <div className="info-segment">
              {/* <div className="img-block">
                <img src={thirtyImage} style={{ marginRight: "30px" }}></img>
              </div> */}
              <div class="sc-gipzik joNsQm">
                <p class="sc-csuQGl kMPoVz">
                  <span class="sc-gPEVay bNalEw">30+</span>
                </p>
              </div>
              <TextBlock>
                <TextBlockTitile>
                  <Text tid="SELL.AGENCY.TECHNOLOGICAL_TOOLS" />
                </TextBlockTitile>
                <TextBlockSubtitle>
                  <Text tid="SELL.AGENCY.TOOLS_COUNT" />
                </TextBlockSubtitle>
              </TextBlock>
            </div>
            <div className="info-segment">
              {/* <div className="img-block">
                <img src={millionsImage} style={{ marginRight: "30px" }}></img>
              </div> */}
              <div class="sc-gipzik joNsQm">
                <p class="sc-csuQGl kMPoVz">
                  <span class="sc-gPEVay bNalEw">
                    4+
                    <br />
                    <Text tid="SELL.AGENCY.TOOLS_COUNT1" />
                  </span>
                </p>
              </div>
              <TextBlock>
                <TextBlockTitile>
                  <Text tid="SELL.AGENCY.LINES_OF_CODE" />
                </TextBlockTitile>
                <TextBlockSubtitle>
                  <Text tid="SELL.AGENCY.CODE_LINES_COUNT" />
                </TextBlockSubtitle>
              </TextBlock>
            </div>
            <div className="info-segment">
              {/* <div className="img-block">
                <img src={tenImage} style={{ marginRight: "30px" }}></img>
              </div> */}
              <div class="sc-gipzik joNsQm">
                <p class="sc-csuQGl kMPoVz">
                  <span class="sc-gPEVay bNalEw">10</span>
                </p>
              </div>
              <TextBlock>
                <TextBlockTitile>
                  <Text tid="SELL.AGENCY.TEAM_SIZE" />
                </TextBlockTitile>
                <TextBlockSubtitle>
                  <Text tid="SELL.AGENCY.TEAM_SIZE_COUNT" />
                </TextBlockSubtitle>
              </TextBlock>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
