import React from "react";
import "../QuestionBlock/index.css";
import "../QuestionBlock/adaptive.css";
import { Text } from "../../utils/Text";

function ContactUs() {
  const handleTelClick = () => {
    window.open("https://t.me/thedarkagency", "_blank");
  };
  return (
    <div className="content-wraper" id="contact-us-block">
      <div className="title">
        <Text tid="SELL.AGENCY.WHY_IT_SOLUTIONS" />
      </div>
      <div className="subtitle-cu">
        <Text tid="SELL.AGENCY.BENEFITS_OF_IT_SOLUTIONS" />
      </div>
      <button className="contact-us-button" onClick={handleTelClick}>
        <Text tid="SELL.AGENCY.CONTACT_US" />
      </button>
    </div>
  );
}

export default ContactUs;
