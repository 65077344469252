import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import lang from "../lang";

export const LANG = {
  EN: "en",
  RU: "ru",
  UA: "ua",
};

const langList = Object.values(LANG);

i18n.use(initReactI18next).init({
  resources: lang,
  fallbackLng: LANG.RU,
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: langList,
});

export { i18n };
