import React, { useState, useEffect } from "react";
import "../Slider/index.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import cardImage1 from "./img/Screenshot_2021-09-08_at_20.44.02.png";
import cardImage2 from "./img/Payment no popup Copy 3.png";
import cardImage3 from "./img/ðíÔëá┬«ÐÆðô╠üÔäó ð¢Ôäóð░ÔÇáÔëáÔÇá 2021-04-07 ÊÉ 18.29.37.png";
import cardImage4 from "./img/kinohouse_1.png";
import rightArrowImage from "./img/arrowRight.png";
import leftArrowImage from "./img/arrowLeft.png";
import { Link } from "react-router-dom";
import { Text } from "../../utils/Text";
import { useTranslation } from "react-i18next";

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canClick, setCanClick] = useState(true);

  const { t } = useTranslation();

  const exchange = t("SELL.AGENCY.EXCHANGE");
  const dc = t("SELL.AGENCY.DC");
  const csgo = t("SELL.AGENCY.CSGOT");
  const kh = t("SELL.AGENCY.KH");

  const slides = [
    {
      image: cardImage4,
      title: kh,
    },
    {
      image: cardImage1,
      title: exchange,
    },
    {
      image: cardImage2,
      title: dc,
    },
    {
      image: cardImage3,
      title: csgo,
    },
  ];

  const handleNextClick = () => {
    if (canClick) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
      setCanClick(false);
    }
  };

  const handlePrevClick = () => {
    if (canClick) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
      );
      setCanClick(false);
    }
  };

  const currentClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanClick(true);
    }, 600);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  return (
    <React.Fragment>
      <div className="slider-content">
        <button
          className="left-button"
          onClick={handlePrevClick}
          disabled={!canClick}
        >
          <p className="left-button-arrow">&lt;</p>
        </button>
        <Link to={`/slide${currentIndex + 1}`} className="link">
          <div className="image-container">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`slide ${index === currentIndex ? "active" : ""}`}
              >
                <span className="slider-title">{slide.title}</span>
                <img src={slide.image} alt="Slide" className="slider-image" />
              </div>
            ))}
          </div>
        </Link>
        <button className="right-button" disabled={!canClick}>
          <p className="right-button-arrow" onClick={handleNextClick}>
            &gt;
          </p>
        </button>
      </div>
      <div className="nav-slide-wrapper">
        {slides.map((slide, index) => (
          <button
            key={index}
            className={`nav-slider-button ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => currentClick(index)}
          ></button>
        ))}
      </div>
      <button className="slider-button">
        <Link to={`/slide${currentIndex + 1}`} className="link">
          <Text tid="SELL.AGENCY.VIEW_CASE" />
        </Link>
      </button>
    </React.Fragment>
  );
}

export default Slider;
