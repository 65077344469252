import React from "react";
import "../SliderBlock/index.css";
import Slider from "../Slider/simple-adaptive-slider";
import { Text } from "../../utils/Text";

function SliderBlock() {
  return (
    <div
      className="content-wrapper"
      style={{ backgroundColor: "#191919", padding: "50px" }}
      id="slider-block"
    >
      <div className="title" style={{ marginBottom: "50px" }}>
        <Text tid="SELL.AGENCY.OUR_CASES" />
      </div>
      <div className="slider-wrapper">
        <Slider />
      </div>
    </div>
  );
}

export default SliderBlock;
