import React from "react";
import Header from "../Header";
import "../HeadBlock/index.css";
import "../HeadBlock/adaptive.css";
import TelegramIcon from "./img/telegram.svg";

import { Text } from "../../utils/Text";

const handleTelClick = () => {
  window.open("https://t.me/thedarkagency", "_blank");
};

function HeadBlock() {
  return (
    <div className="wrapper">
      <Header />
      <div className="main-content-wrapper">
        <p className="title" style={{
          textAlign: "center",
          marginBottom: "0px",
        }}>
          <span className="title-not-colored">
            <Text tid="SELL.AGENCY.DEVELOPMENT_SERVICES" />
          </span>
          <span className="title-colored">
            {" "}
            <Text tid="SELL.AGENCY.DEVELOPMENT_SERVICES1" />{" "}
          </span>
          <span className="title-not-colored">
            <Text tid="SELL.AGENCY.DEVELOPMENT_SERVICES2" />
          </span>
        </p>
        <div className="subtitle">
          <Text tid="SELL.AGENCY.OUR_GOAL" />
        </div>
        <div className="button-block">
          <button className=" contact-button" onClick={handleTelClick}>
            <div className="contact-button-text">
              <img src={TelegramIcon} className="contact-button-img" />
              <span>@thedarkagency</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
export default HeadBlock;
