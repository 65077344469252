import React, { useRef, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Text } from "../../utils/Text";

function MoreInfo() {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      title: t("SELL.AGENCY.MORE_INFO_TITLE1"),
      text: t("SELL.AGENCY.MORE_INFO_DESC1"),
      isOpen: false,
      contentRef: useRef(null),
    },
    {
      id: 2,
      title: t("SELL.AGENCY.MORE_INFO_TITLE2"),
      text: t("SELL.AGENCY.MORE_INFO_DESC2"),
      isOpen: false,
      contentRef: useRef(null),
    },
    {
      id: 3,
      title: t("SELL.AGENCY.MORE_INFO_TITLE3"),
      text: t("SELL.AGENCY.MORE_INFO_DESC3"),
      subText: t("SELL.AGENCY.MORE_INFO_SUBDESC3"),
      isOpen: false,
      contentRef: useRef(null),
    },
  ];

  const [isOpen, setIsOpen] = useState(Array(data.length).fill(false));

  const handleMoreInfoClick = (index) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
  };

  const containerStyles = data.map((item, index) => ({
    height: isOpen[index] ? `${item.contentRef.current.scrollHeight}px` : "0px",
    transition: "all 0.3s ease",
  }));

  return (
    <div id="more-info" className="sc-gFaPwZ jcPlNc">
      <div className="sc-htoDjs hHQJjG sc-fhYwyz hVsmJz">
        <div className="sc-kEYyzF dQXzcm">
          <div className="sc-hMqMXs PUTvS">
            <h3 className="sc-jKJlTe kNhVFs">
              <Text tid="SELL.AGENCY.MORE_INFO" />
            </h3>
            <div className="sc-jzgbtB dbbmUa">
              {data.map((item, index) => (
                <div
                  className="sc-bnXvFD iyfePo"
                  onClick={() => handleMoreInfoClick(index)}
                  key={item.id}
                >
                  <div className="sc-eilVRo kINytF">
                    <h3 className="sc-emmjRN fhSElc">{item.title}</h3>
                    <svg
                      width={22}
                      height={12}
                      fill="none"
                      className={`sc-eerKOB ${
                        isOpen[index] ? "jguqji" : "Hazuv"
                      }`}
                    >
                      <path
                        d="M1 1l10 10L21 1"
                        stroke="#fff"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    className={
                      isOpen[index]
                        ? " MuiCollapse-container MuiCollapse-entered"
                        : "MuiCollapse-container"
                    }
                    ref={item.contentRef}
                    style={containerStyles[index]}
                  >
                    <div className="MuiCollapse-wrapper">
                      <div className="MuiCollapse-wrapperInner">
                        <div className="sc-cpmLhU hDagGi">
                          <h2 className="sc-dymIpo gdxZmI">
                            {item.subText === undefined ? (
                              <>{item.text}</>
                            ) : (
                              <>
                                {item.text}
                                <br />
                                <br />
                                {item.subText}
                              </>
                            )}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoreInfo;
