import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import HeadBlock from "./components/HeadBlock";
import ContactUs from "./components/QuestionBlock";
import SliderBlock from "./components/SliderBlock";
import DifficultiesBlock from "./components/DifficultiesBlock";
import Services from "./components/Services";
import Info from "./components/InfoBlock";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import "./utils/i18n";
import MoreInfo from "./components/MoreInfo/MoreInfo";
import { CS, Crypto, Lightnet, KinoHouse } from "./ProjectData/ProjectData";
import ProjectComponent from "./components/ProjectComponent/ProjectComponent";
import Header from "./components/Header";
import Head from "./components/Head/Head";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => { 
  return (
    <React.StrictMode>
      <Head />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <HeadBlock />
                <ContactUs />
                <SliderBlock />
                <DifficultiesBlock />
                <Services />
                {/* <Conditions /> */}
                <MoreInfo />
                <Info />
              </>
            }
          />
          <Route
            exact
            path="/slide4"
            element={
              <>
                <ProjectComponent
                  descriptionTextSub={Crypto.descriptionTextSub}
                  images={Crypto.images}
                  titleText={Crypto.titleText}
                  descriptionText={Crypto.descriptionText}
                  techText={Crypto.techText}
                  descriptionTitleText={Crypto.descriptionTitleText}
                />
                {/* <Crypto /> */}
                <Info />
              </>
            }
          />
          <Route
            exact
            path="/slide3"
            element={
              <>
                <ProjectComponent
                  descriptionTextSub={CS.descriptionTextSub}
                  images={CS.images}
                  titleText={CS.titleText}
                  descriptionText={CS.descriptionText}
                  techText={CS.techText}
                  descriptionTitleText={CS.descriptionTitleText}
                />
                {/* <Cs /> */}
                <Info />
              </>
            }
          />
          <Route
            exact
            path="/slide2"
            element={
              <>
                <ProjectComponent
                  descriptionTextSub={Lightnet.descriptionTextSub}
                  images={Lightnet.images}
                  titleText={Lightnet.titleText}
                  descriptionText={Lightnet.descriptionText}
                  techText={Lightnet.techText}
                  descriptionTitleText={Lightnet.descriptionTitleText}
                />
                {/* <Lightnet /> */}
                <Info />
              </>
            }
          />
          <Route
            exact
            path="/slide1"
            element={
              <>
                <ProjectComponent
                  descriptionTextSub={KinoHouse.descriptionTextSub}
                  images={KinoHouse.images}
                  titleText={KinoHouse.titleText}
                  descriptionText={KinoHouse.descriptionText}
                  techText={KinoHouse.techText}
                  descriptionTitleText={KinoHouse.descriptionTitleText}
                />
                {/* <Lightnet /> */}
                <Info />
              </>
            }
          />
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

reportWebVitals();
