import CryptoImage1 from "./img/Screenshot_2021-09-08_at_20.44.02.png";
import CryptoImage2 from "./img/Screenshot_2021-09-08_at_20.44.05.png";
import CryptoImage3 from "./img/Screenshot_2021-09-08_at_20.44.09.png";

import CSImage1 from "./img/ðíÔëá┬«ÐÆðô╠üÔäó ð¢Ôäóð░ÔÇáÔëáÔÇá 2021-04-07 ÊÉ 18.29.22.png";
import CSImage2 from "./img/ðíÔëá┬«ÐÆðô╠üÔäó ð¢Ôäóð░ÔÇáÔëáÔÇá 2021-04-07 ÊÉ 18.29.32.png";
import CSImage3 from "./img/ðíÔëá┬«ÐÆðô╠üÔäó ð¢Ôäóð░ÔÇáÔëáÔÇá 2021-04-07 ÊÉ 18.29.37.png";
import CSImage4 from "./img/ðíÔëá┬«ÐÆðô╠üÔäó ð¢Ôäóð░ÔÇáÔëáÔÇá 2021-04-07 ÊÉ 18.29.46.png";
import CSImage5 from "./img/ðíÔëá┬«ÐÆðô╠üÔäó ð¢Ôäóð░ÔÇáÔëáÔÇá 2021-04-07 ÊÉ 18.29.53.png";

import LightnetImage5 from "./img/Ethereum payment Copy 2.png";
import LightnetImage2 from "./img/Ethereum payment Copy 15.png";
import LightnetImage3 from "./img/Ethereum payment Copy 9.png";
import LightnetImage4 from "./img/Payment 5.png";
import LightnetImage1 from "./img/Payment no popup Copy 3.png";
import LightnetImage6 from "./img/Payment4 Add token.png";
import LightnetImage7 from "./img/Payment4 pop up.png";
import LightnetImage8 from "./img/Payment4.png";
import LightnetImage9 from "./img/Payment5.1.png";
import LightnetImage10 from "./img/Payment5.2.2 Copy 5.png";

import kinohouse_1 from "./img/kinohouse_1.png";
import kinohouse_2 from "./img/kinohouse_2.png";
import kinohouse_3 from "./img/kinohouse_3.png";
import kinohouse_4 from "./img/kinohouse_4.png";
import kinohouse_5 from "./img/kinohouse_5.png";
import kinohouse_6 from "./img/kinohouse_6.png";
import kinohouse_7 from "./img/kinohouse_7.png";
import kinohouse_8 from "./img/kinohouse_8.png";
import kinohouse_9 from "./img/kinohouse_9.png";

const KinoHouse = {
  images: [
    kinohouse_1,
    kinohouse_2,
    kinohouse_3,
    kinohouse_4,
    kinohouse_5,
    kinohouse_6,
    kinohouse_7,
    kinohouse_8,
    kinohouse_9,
  ],
  titleText: "SELL.AGENCY.KH",
  descriptionTitleText: "SELL.AGENCY.KHDESC",
  descriptionText: [
    "SELL.AGENCY.KHDES1",
    "SELL.AGENCY.KHDES2",
    "SELL.AGENCY.KHDES3",
  ],
  descriptionTextSub: [
    "SELL.AGENCY.KHDES1SUB",
    "SELL.AGENCY.KHDES2SUB",
    "SELL.AGENCY.KHDES3SUB",
  ],

  techText: "React.js, Next.js, Redux, Styled-components, Node.js, Express.js",
};

const CS = {
  images: [CSImage1, CSImage2, CSImage3, CSImage4, CSImage5],
  titleText: "SELL.AGENCY.CSGO",
  descriptionTitleText: "SELL.AGENCY.CS",
  descriptionText: [
    "SELL.AGENCY.WORK1CS",
    "SELL.AGENCY.WORK2CS",
    "SELL.AGENCY.WORK3CS",
  ],
  descriptionTextSub: [
    "SELL.AGENCY.WORK1CSSUB",
    "SELL.AGENCY.WORK2CSSUB",
    "SELL.AGENCY.WORK3CSSUB",
  ],
  techText:
    "React.js, Next.js, Redux, Styled-components, Node.js, Express.js, Nest.js, Typeorm.js, PostgreSQL",
};

const Crypto = {
  images: [CryptoImage1, CryptoImage2, CryptoImage3],
  titleText: "SELL.AGENCY.EXCHANGE",
  descriptionTitleText: "SELL.AGENCY.CE",
  descriptionText: [
    "SELL.AGENCY.WORK1",
    "SELL.AGENCY.WORK2",
    "SELL.AGENCY.WORK3",
  ],
  descriptionTextSub: [
    "SELL.AGENCY.WORK1SUB",
    "SELL.AGENCY.WORK2SUB",
    "SELL.AGENCY.WORK3SUB",
  ],
  techText:
    "Solidity, Vue, Vuex, Vuetify, Nuxt.js, Node.js, Express.js, Nest.js, Typeorm.js, PostgreSQL",
};

const Lightnet = {
  images: [
    LightnetImage1,
    LightnetImage2,
    LightnetImage3,
    LightnetImage4,
    LightnetImage5,
    LightnetImage6,
    LightnetImage7,
    LightnetImage8,
    LightnetImage9,
    LightnetImage10,
  ],
  titleText: "SELL.AGENCY.DC",
  descriptionTitleText: "SELL.AGENCY.LN",
  descriptionText: [
    "SELL.AGENCY.WORK1LN",
    "SELL.AGENCY.WORK2LN",
    "SELL.AGENCY.WORK3LN",
    "SELL.AGENCY.WORK4LN",
  ],
  descriptionTextSub: [
    "SELL.AGENCY.WORK1LNSUB",
    "SELL.AGENCY.WORK2LNSUB",
    "SELL.AGENCY.WORK3LNSUB",
    "SELL.AGENCY.WORK4LNSUB",
  ],

  techText:
    "Solidity, Vue, Vuex, Vuetify, Nuxt.js, Node.js, Express.js, Nest.js, Typeorm.js, PostgreSQL",
};

export { CS, Crypto, Lightnet, KinoHouse };
