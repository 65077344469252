import React, { useState, useEffect } from 'react';
import './index.css';
import { CSSTransition } from 'react-transition-group';
import Header from '../Header';
import { Text } from '../../utils/Text';

function ProjectComponent({
  images,
  titleText,
  descriptionText,
  techText,
  descriptionTitleText,
  descriptionTextSub,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canClick, setCanClick] = useState(true);
  const [isImageOpen, setIsImageOpen] = useState(false);

  const handleImageClick = () => {
    setIsImageOpen(!isImageOpen);
  };

  const handleNextClick = () => {
    if (canClick) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setCanClick(false);
    }
  };

  const handlePrevClick = () => {
    if (canClick) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
      setCanClick(false);
    }
  };

  const currentClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanClick(true);
    }, 600);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  return (
    <div className="crypto">
      <CSSTransition
        in={isImageOpen}
        timeout={300}
        classNames="fullscreen-image"
        unmountOnExit
      >
        <div
          className="fullscreen-image-container"
          onClick={handleImageClick}
        >
          <img
            src={images[currentIndex]}
            alt="Fullscreen Image"
            className="fullscreen-image"
          />
          <button className="close-button">X</button>
        </div>
      </CSSTransition>
      <div className="Crypto-container">
        <div className="Crypto-descBox">
          <Header />
          <div className="Crypto-descBoxText">
            <div className="Crypto-textblock">
              <h1 className="Crypto-title">
                <Text tid={titleText} />
              </h1>
              <p className="Crypto-text">
                <Text tid={descriptionTitleText} />
              </p>
              <br />
              <p className="Crypto-text">
                <Text tid="SELL.AGENCY.TECH" /> <b>{techText}</b>
              </p>
            </div>
            <div className="Crypto-imgblock">
              <img
                className="Crypto-img"
                src={images[0]}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* Crypto-slider */}
        <div className="Crypto-slider">
          <div className="slider-content crypto-slider-content">
            <button
              className="left-button"
              onClick={handlePrevClick}
              disabled={!canClick}
            >
              <p className="left-button-arrow">&lt;</p>
            </button>
            <div className="image-container image-containerProject">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`slide ${index === currentIndex ? 'active' : ''}`}
                >
                  <img
                    src={image}
                    alt="Slide"
                    className="slider-image slider-imageProject"
                    onClick={handleImageClick}
                  />
                </div>
              ))}
            </div>
            <button
              className="right-button"
              disabled={!canClick}
            >
              <p
                className="right-button-arrow"
                onClick={handleNextClick}
              >
                &gt;
              </p>
            </button>
          </div>
          <div className="nav-slide-project">
            {images.map((image, index) => (
              <button
                key={index}
                className={`nav-slider-button ${
                  index === currentIndex ? 'active' : ''
                } ${images.length > 5 ? 'dot' : ''}`}
                onClick={() => currentClick(index)}
              ></button>
            ))}
          </div>
        </div>
        {/* Crypto-slider */}
        <div className="Crypto-descBoxMain">
          <div className="Crypto-decsContentBox">
            <div className="Crypto-descBoxMain-title">
              <h3
                color="secondary"
                className="sc-jKJlTe lBWzE sc-fONwsr gVyYhf"
              >
                <Text tid="SELL.AGENCY.WORK" />
              </h3>
            </div>
            <ul className="sc-hmXxxW jWyyKW">
              {descriptionText.map((text, index) => (
                <li
                  key={index}
                  className="sc-cqCuEk kzBtc"
                >
                  <div className="sc-dliRfk lmGcJd">
                    <span className="sc-kLIISr gPTYXp">{index + 1}</span>
                  </div>
                  <div className="sc-qrIAp gOfvOs">
                    <h3 className="sc-iqzUVk bdEQYe">
                      <Text tid={text} />
                    </h3>
                    <p className='descriptionTextSub'>
                      <Text tid={descriptionTextSub[index]} />
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectComponent;
